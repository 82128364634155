import { DEFAULT_PAGE_SIZE } from './../components/constant';
import { mergeWith, isArray, cloneDeep } from 'lodash';

type DefaultObject = Record<string, unknown>;

const DEFAULT_PAGINATION_PARAMS = {
  pagination: {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  },
} as const;

export const mergeParams = (first: DefaultObject, second: DefaultObject) =>
  mergeWith(cloneDeep(first), second, (a, b) => (isArray(b) ? b : undefined));

export const buildPaginationParams = (params: DefaultObject = {}) =>
  mergeParams(DEFAULT_PAGINATION_PARAMS, params);

export const handleReCaptchaVerify = async (
  executeRecaptcha:
    | ((action?: string | undefined) => Promise<string>)
    | undefined,
  key: string,
) => {
  if (!executeRecaptcha) {
    console.log('Execute recaptcha not yet available');
    return;
  }
  // This is the same as grecaptcha.execute on traditional html script tags
  const token = await executeRecaptcha(key);
  const response = await fetch('/verify-captcha', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
  });
  const data = await response.json();
  return response?.status === 200;
};
