import { Modal } from 'antd';
import React from 'react';
import { BrochureCardType } from 'components/types';
import BrochureModalContent from './components/BrochureModalContent';

export interface DownloadMultipleBrochuresModalProps {
  currentBrochureIndex: number;
  brochureList: BrochureCardType[];
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DownloadMultipleBrochuresModal = (
  props: DownloadMultipleBrochuresModalProps,
) => {
  return (
    <>
      <Modal
        open={props.isModalOpen}
        footer={null}
        width={1300}
        closable
        onCancel={() => props.setIsModalOpen(false)}
        destroyOnClose
      >
        <BrochureModalContent {...props} />
      </Modal>
    </>
  );
};

export default DownloadMultipleBrochuresModal;
