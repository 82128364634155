import React from 'react';

const downloadPdf = (url: string, filename: string) =>
  fetch(url).then(t =>
    t
      .blob()
      .then(b => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', `${filename}.pdf`);
        a.click();
      })
      .catch(),
  );

export default downloadPdf;
