import * as React from 'react';
import NewLayout from 'components/NewLayout';
import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import { graphql } from 'gatsby';
import BrochuresIndex from 'components/pages/brochures';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';

const pageUrl = `${PREFIX_CANONICAL_URL}/brochures`;

const BrochuresPage = () => {
  return (
    <LanguageProvider messages={translation}>
      <NewLayout headerStyle="light">
        <BreadcrumbList
          pageLabelId="structuredData.brochures"
          pageUrl={pageUrl}
        />
        <BrochuresIndex />
      </NewLayout>
    </LanguageProvider>
  );
};
export default BrochuresPage;

export const Head = ({
  data,
}: {
  data: {
    allStrapiBrochuresPage: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL
      data={data.allStrapiBrochuresPage.nodes[0]}
      canonicalURL={pageUrl}
    />
  );
};

export const query = graphql`
  query {
    allStrapiBrochuresPage {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaTitle
          metaDescription
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
