import React from 'react';
import Footer from 'components/footer/';
import Header from './header';
import { HeaderStyleType } from './header/type';
import LayoutContainer from './LayoutContainer';
import CookieConsent from './cookieConsent';

interface LayoutProps {
  children: React.ReactNode;
  headerStyle: HeaderStyleType;
}

const NewLayout = ({ children, headerStyle }: LayoutProps) => {
  return (
    <>
      <CookieConsent />
      <Header headerStyle={headerStyle} />
      <main className="overflow-hidden">{children}</main>
      <div className="bg-hubble_blue">
        <LayoutContainer>
          <Footer />
        </LayoutContainer>
      </div>
    </>
  );
};

export default NewLayout;
