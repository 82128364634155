import { FAQType } from 'components/productTemplate/types';
import {
  PREFIX_CANONICAL_URL,
  StructuredDataTypes,
  STRUCTURED_DATA_CONTEXT,
} from '../constant';
import {
  BreadcrumbListType,
  BreadcrumbItemType,
  WebPageType,
  ProductStructuredDataRemote,
  ProductPageStructuredDataType,
  ProductStructuredDataType,
  FAQPageStructureDataType,
  QuestionStructuredDataType,
  VideoObjectType,
} from './types';

export const getFirstElementOfBreadcrumbList = (
  formatMessage: Function,
): BreadcrumbItemType => ({
  '@type': StructuredDataTypes.LIST_ITEM,
  position: 1,
  name: formatMessage({ id: 'structuredData.home' }),
  item: PREFIX_CANONICAL_URL,
});

export const createBreadcrumbListHFSStructuredData = (
  pageUrl: string,
  pageLabel: string,
  formatMessage: Function,
): BreadcrumbListType => {
  return {
    '@type': StructuredDataTypes.BREADCRUMB_LIST,
    '@id': `${pageUrl}#breadcrumb`,
    itemListElement: [
      getFirstElementOfBreadcrumbList(formatMessage),
      {
        '@type': StructuredDataTypes.LIST_ITEM,
        position: 2,
        name: formatMessage({ id: 'structuredData.hfs' }),
        item: `${PREFIX_CANONICAL_URL}/financial`,
      },
      {
        '@type': StructuredDataTypes.LIST_ITEM,
        position: 3,
        name: pageLabel,
      },
    ],
  };
};

export const createBreadcrumbListStructuredData = (
  pageUrl: string,
  pageLabel: string,
  formatMessage: Function,
): BreadcrumbListType => {
  return {
    '@type': StructuredDataTypes.BREADCRUMB_LIST,
    '@id': `${pageUrl}#breadcrumb`,
    itemListElement: [
      getFirstElementOfBreadcrumbList(formatMessage),
      {
        '@type': StructuredDataTypes.LIST_ITEM,
        position: 2,
        name: pageLabel,
      },
    ],
  };
};

const createWebPageStructuredData = (
  pageUrl: string,
  metaTitle: string,
  metaDescription: string,
): WebPageType => {
  return {
    '@type': StructuredDataTypes.WEB_PAGE,
    '@id': `${pageUrl}#webpage`,
    url: `${pageUrl}`,
    name: metaTitle,
    description: metaDescription,
    breadcrumb: {
      '@id': `${pageUrl}#breadcrumb`,
    },
  };
};

const createProductStructuredData = (
  productData: ProductStructuredDataRemote,
): ProductStructuredDataType => {
  const { name, description, ratingValue, bestRating, reviewCount } =
    productData;
  return {
    '@type': StructuredDataTypes.PRODUCT,
    name,
    description,
    aggregateRating: {
      '@type': StructuredDataTypes.AGGREGATE_RATING,
      ratingValue,
      bestRating,
      reviewCount,
    },
  };
};

export const createProductPageStructuredData = (
  pageUrl: string,
  metaTitle: string,
  metaDescription: string,
  pageLabel: string,
  productData: ProductStructuredDataRemote,
  formatMessage: Function,
): ProductPageStructuredDataType => {
  const webPage = createWebPageStructuredData(
    pageUrl,
    metaTitle,
    metaDescription,
  );
  const breadcrumbList = createBreadcrumbListStructuredData(
    pageUrl,
    pageLabel,
    formatMessage,
  );
  const productStructureData = createProductStructuredData(productData);

  return {
    '@context': STRUCTURED_DATA_CONTEXT,
    '@graph': [webPage, breadcrumbList, productStructureData],
  };
};
export const createHFSProductPageStructuredData = (
  pageUrl: string,
  metaTitle: string,
  metaDescription: string,
  pageLabel: string,
  formatMessage: Function,
): ProductPageStructuredDataType => {
  const webPage = createWebPageStructuredData(
    pageUrl,
    metaTitle,
    metaDescription,
  );
  const breadcrumbList = createBreadcrumbListHFSStructuredData(
    pageUrl,
    pageLabel,
    formatMessage,
  );

  return {
    '@context': STRUCTURED_DATA_CONTEXT,
    '@graph': [webPage, breadcrumbList],
  };
};

export const createVideoObjectStructuredData = ({
  name,
  description,
  thumbnailUrl,
  embedUrl,
  uploadDate,
}: Omit<VideoObjectType, '@context' | '@type'>): VideoObjectType => {
  return {
    '@context': STRUCTURED_DATA_CONTEXT,
    '@type': StructuredDataTypes.VIDEO_OBJECT,
    name,
    description,
    thumbnailUrl,
    embedUrl,
    uploadDate,
  };
};

const getMainEntityList = (list: FAQType[]): QuestionStructuredDataType[] => {
  const data = list.map(({ q, a }) => ({
    '@type': StructuredDataTypes.QUESTION as const,
    name: q,
    acceptedAnswer: {
      '@type': StructuredDataTypes.ANSWER as const,
      text: a,
    },
  }));
  return data;
};

export const createFAQPageStructuredData = (
  list: FAQType[],
): FAQPageStructureDataType => {
  return {
    '@context': STRUCTURED_DATA_CONTEXT,
    '@type': StructuredDataTypes.FAQPAGE,
    mainEntity: getMainEntityList(list),
  };
};
