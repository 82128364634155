import Search from 'antd/lib/input/Search';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';

interface BrochuresHeroProps {
  setSearchKey: (arg0: string) => void;
}

const HeroWrapper = tw.div`
    mt-[84px] px-[30px] lg:px-0 text-base_text h-[30vh] flex flex-col justify-center items-center  text-center 
`;
const Heading = tw.h3`
    text-[36px] lg:text-[48px] m-0 lg:mb-2 text-base_text 
`;
const SubHeading = tw.h1`
    text-[14px] lg:text-[18px] mb-2
`;
const WebSearchWrapper = tw.div`
  hidden lg:block
`;
const BrochuresHero = ({ setSearchKey }: BrochuresHeroProps) => {
  const { formatMessage } = useIntl();
  const data = useStaticQuery(graphql`
    query {
      allStrapiBrochuresPage {
        nodes {
          PageHeader
          PageSubHeader
        }
      }
    }
  `);
  const { PageHeader, PageSubHeader } = data.allStrapiBrochuresPage.nodes[0];
  return (
    <HeroWrapper>
      <Heading>{PageHeader}</Heading>
      <SubHeading>{PageSubHeader}</SubHeading>
      <WebSearchWrapper>
        <Search
          placeholder={formatMessage({
            id: 'brochures.filter.search.placeholder',
          })}
          allowClear
          onSearch={searchKey => setSearchKey(searchKey)}
          style={{
            width: '678px',
            color: '#D8DADE',
          }}
          size="large"
        />
      </WebSearchWrapper>
    </HeroWrapper>
  );
};

export default BrochuresHero;
