import { Modal } from 'antd';
import React, { useState } from 'react';
import ArticleTagsV2 from './ArticleTagsV2';
import tw from 'twin.macro';
import { BrochureCardType } from 'components/types';
import DownloadMultipleBrochuresModal from 'components/pages/brochures/DownloadMultipleBrochuresModal';

interface BrochureCardProps extends BrochureCardType {
  brochureList: BrochureCardType[];
  currentBrochureIndex: number;
}

const CardWrapper = tw.button`
  grid grid-cols-[1fr_2fr] gap-0 shadow-[0px_1px_5px_rgba(41,45,55,0.15)] 
`;
const ContentWrapper = tw.div`
px-4 py-6 text-start bg-white h-full
`;
const CardName = tw.p`
  text-[18px] font-[500] mb-2 mt-4 
`;

const BrochureCard = ({
  type,
  name,
  description,
  imgSrc,
  brochureList,
  currentBrochureIndex,
}: BrochureCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <CardWrapper onClick={showModal} className="group">
        <img
          src={imgSrc}
          alt="brochure-img"
          className="bg-accent_grey_1 object-cover h-full w-full max-h-[250px]"
        />
        <ContentWrapper className="group-hover:bg-hubble_blue">
          <ArticleTagsV2 name={type?.name} />
          <CardName className="group-hover:text-white">{name}</CardName>
          <span className="text-[16px] group-hover:text-white">
            {description}
          </span>
        </ContentWrapper>
      </CardWrapper>
      <DownloadMultipleBrochuresModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        brochureList={brochureList}
        currentBrochureIndex={currentBrochureIndex}
      />
    </>
  );
};

export default BrochureCard;
