import { RefObject } from 'react';

const EXTRA_OFFSET_TOP = 120;
const DEFAULT_SCROLL_DELAY = 0;

const useListScroll = (
  itemRef: RefObject<HTMLDivElement>,
  scrollDelay: number = DEFAULT_SCROLL_DELAY,
) => {
  const onScroll = () => {
    setTimeout(() => {
      window.scrollTo({
        top:
          (itemRef?.current?.offsetTop ?? EXTRA_OFFSET_TOP) - EXTRA_OFFSET_TOP,
        behavior: 'smooth',
      });
    }, scrollDelay);
  };

  return onScroll;
};

export default useListScroll;
