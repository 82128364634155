import React from 'react';

interface LayoutContainerProps {
  children: React.ReactNode;
  className?: string;
}
const LayoutContainer = ({
  children,
  className = '',
}: LayoutContainerProps) => {
  return (
    <div className="lg:px-[72px] xl:px-[max(120px,calc((100vh-1272px)/2))] relative">
      <div className={`max-w-[1272px] xl:min-w-[1200px] mx-auto ${className}`}>
        {children}
      </div>
    </div>
  );
};

export default LayoutContainer;
