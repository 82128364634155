import React from 'react';

const withConvertToFilterList = (
  data: { name: string; slug: string }[],
  key: string,
  label: string,
) => {
  return {
    key,
    label,
    items: data?.map(({ name, slug }) => ({
      value: slug as string,
      label: name as string,
    })),
  };
};

export default withConvertToFilterList;
