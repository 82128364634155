import BrochureCard from 'components/articleList/BrochureCard';
import useListScroll from 'components/articleList/hooks/useListScroll';
import MobilePagination from 'components/articleList/MobilePagination';
import WebPagination from 'components/articleList/WebPagination';
import { BrochureCardType } from 'components/types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

const PAGE_SIZE = 12;
interface BrochuresListProps {
  currentList: BrochureCardType[];
  brochureList: BrochureCardType[];
}
const BrochuresList = ({ currentList, brochureList }: BrochuresListProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const listRef = useRef<HTMLDivElement>(null);
  const startScrollingRef = useRef<boolean>(false);
  const onListScroll = useListScroll(listRef);
  useLayoutEffect(() => {
    if (startScrollingRef.current) {
      onListScroll();
      startScrollingRef.current = false;
    }
  }, []);
  const startScrolling = () => {
    startScrollingRef.current = true;
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [currentList]);

  const totalItems = currentList.length ?? 1;

  const offset = (currentPage - 1) * PAGE_SIZE;
  const end = offset + PAGE_SIZE > totalItems ? totalItems : offset + PAGE_SIZE;

  const currentListPerPage = currentList.slice(offset, end);
  return (
    <>
      <div
        ref={listRef}
        className="grid grid-cols-1 lg:grid-cols-2 gap-[40px] lg:gap-y-6 px-[30px] lg:px-0 mb-4 mt-[40px]"
      >
        {currentListPerPage.map((card, index) => (
          <BrochureCard
            key={index}
            {...card}
            currentBrochureIndex={index}
            brochureList={brochureList}
          />
        ))}
      </div>
      {totalItems > PAGE_SIZE && (
        <>
          <WebPagination
            totalItems={totalItems}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
            onListScroll={startScrolling}
          />

          <MobilePagination
            totalItems={totalItems}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
            onListScroll={startScrolling}
          />
        </>
      )}
    </>
  );
};

export default BrochuresList;
