import { BrochureCardType } from 'components/types';
import React from 'react';
import JsZip from 'jszip';
import FileSaver from 'file-saver';

const download = async (url: string, name: string) => {
  const resp = await fetch(url);
  return { name, blob: resp.blob() };
};

const exportZip = (blobs: { name: string; blob: Promise<Blob> }[]) => {
  const zip = JsZip();
  blobs.forEach(({ name, blob }) => {
    zip.file(`${name} Brochure.pdf`, blob);
  });
  zip.generateAsync({ type: 'blob' }).then(zipFile => {
    const fileName = `Hubble Brochures.zip`;
    return FileSaver.saveAs(zipFile, fileName);
  });
};

const downloadMultipleFiles = async (
  brochureList: BrochureCardType[],
  selectedBrochuresIndexList: number[],
) => {
  const blobList = await Promise.all(
    selectedBrochuresIndexList.map(index =>
      download(brochureList[index].downloadLink, brochureList[index].name),
    ),
  );
  return exportZip(blobList);
};

export default downloadMultipleFiles;
