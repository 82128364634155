import Search from 'antd/lib/input/Search';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';
import MobileSortModal from 'components/filter/MobileSortModal';
import { SortType } from 'components/filter/type';

interface BrochuresFilterProps {
  setSearchKey: (arg0: string) => void;
  typeList: SortType;
  sortSelected: string;
  setSortSelected: React.Dispatch<React.SetStateAction<string>>;
}

const ButtonLabelContainer = tw.button`
  flex gap-3 text-base_text justify-center items-center hover:opacity-50 h-[100%] bg-accent_grey_1 w-[100%] py-[24px]
`;
const MobileSearchAndFilterWrapper = tw.div`
    lg:hidden mb-[40px]
`;
const WebContainer = tw.div`
    hidden lg:block mt-[86px]
`;
const TypeListWrapper = tw.div`
    inline-flex flex-row gap-8 text-accent_blue_2
`;
const TypeButton = tw.button`
    hover:opacity-50 text-[16px] font-[500]
`;

const BrochuresFilter = ({
  setSearchKey,
  typeList,
  sortSelected,
  setSortSelected,
}: BrochuresFilterProps) => {
  const { formatMessage } = useIntl();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  return (
    <div>
      <MobileSearchAndFilterWrapper>
        <div className="mb-4 px-[30px]">
          <Search
            placeholder={formatMessage({
              id: 'brochures.filter.search.mobile.placeholder',
            })}
            allowClear
            onSearch={searchKey => setSearchKey(searchKey)}
            style={{
              width: '100%',
              color: '#D8DADE',
            }}
            size="large"
          />
        </div>

        <ButtonLabelContainer onClick={() => setIsFilterModalOpen(true)}>
          <span className={`fa-light fa-filter text-[20px]`} />
          <span className="text-[16px]">
            {formatMessage({
              id: 'brochures.filter',
            })}
          </span>
        </ButtonLabelContainer>
        {isFilterModalOpen && (
          <MobileSortModal
            setIsModalOpen={setIsFilterModalOpen}
            isModalOpen={isFilterModalOpen}
            sortList={typeList}
            sortSelected={sortSelected}
            setSortSelected={setSortSelected}
          />
        )}
      </MobileSearchAndFilterWrapper>
      <WebContainer>
        <span className="text-[18px] font-[500] mr-5">
          {formatMessage({
            id: 'brochures.filter.type',
          })}
        </span>
        <TypeListWrapper>
          {typeList.items.map(({ value, label }) => {
            const selectedCSS =
              value === sortSelected ? 'underline underline-offset-4' : '';
            return (
              <TypeButton
                onClick={e =>
                  setSortSelected((e.target as HTMLButtonElement).value)
                }
                key={value}
                value={value}
                className={selectedCSS}
              >
                {label}
              </TypeButton>
            );
          })}
        </TypeListWrapper>
      </WebContainer>
    </div>
  );
};

export default BrochuresFilter;
