import { Select } from 'antd';
import downloadPdf from 'components/downloadPdf';
import HubspotForm from 'components/HubspotForm';
import useNotification from 'components/notification/useNotification';
import { NotificationTypes } from 'components/types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import tw, { styled } from 'twin.macro';
import { DownloadMultipleBrochuresModalProps } from '../DownloadMultipleBrochuresModal';
import downloadMultipleFiles from './downloadMultipleFiles';

interface OptionLabelProps {
  name: string;
  description: string;
}

const ModalWrapper = styled.div`
  ${tw`grid grid-cols-1 lg:grid-cols-[1fr_2fr] gap-[40px] pt-[55px] pb-[36px]`}
  .select-option {
    ${tw`px-4 py-0 relative`}
    .ant-select-item-option-content {
      ${tw`px-1 grid grid-cols-[10px_auto] gap-3 relative`}
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    ${tw`text-[inherit] font-[400] bg-[transparent]`}
  }
`;

const ModalImg = tw.img`
  max-h-[100%] w-full hidden lg:block self-center
`;
const ModalName = tw.p`
  text-[36px] lg:text-[48px] font-[500] mb-0 text-accent_blue_1
`;
const ModalDescription = tw.p`
  text-[14px] lg:text-[18px] mb-[26px] lg:mb-9 text-accent_blue_1
`;
const OptionLabelWrapper = tw.div`
text-[12px] lg:text-[14px] leading-[18px] lg:leading-[21px] py-4 whitespace-pre-line
`;
const MultipleSelectDescription = tw.p`
text-[14px] lg:text-[16px] text-[#585C67] mb-2
`;
const { Option } = Select;

const OptionLabel = ({ name, description }: OptionLabelProps) => {
  return (
    <OptionLabelWrapper>
      <p className="text-base_text mb-0">{name}</p>
      <p className="text-[#9297A3] mb-0">{description}</p>
    </OptionLabelWrapper>
  );
};
const BrochureModalContent = ({
  currentBrochureIndex,
  brochureList,
  setIsModalOpen,
}: DownloadMultipleBrochuresModalProps) => {
  const { formatMessage } = useIntl();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { showNotification } = useNotification({
    type: NotificationTypes.BROCHURES,
    key: `multipleDownload-${currentBrochureIndex}`,
  });
  useEffect(() => {
    if (isSubmitted) {
      showNotification();
    }
  }, [isSubmitted]);

  const firstSelectedBrochure = brochureList[currentBrochureIndex];
  let selectedBrochuresIndexList = [currentBrochureIndex];
  const currentDate = new Date().getTime();

  return (
    <ModalWrapper id="download-multiple-brochures-modal">
      <ModalImg src={firstSelectedBrochure?.imgSrc} alt="brochure-modal-img" />
      <div>
        <ModalName>
          {formatMessage({ id: 'brochures.modal.heading' })}
        </ModalName>
        <ModalDescription>
          {formatMessage({ id: 'brochures.modal.description' })}
        </ModalDescription>
        <MultipleSelectDescription>
          {formatMessage({ id: 'brochures.modal.selectMoreBrochures' })}
        </MultipleSelectDescription>
        <Select
          mode="multiple"
          style={{ width: '100%', marginBottom: '28px' }}
          onChange={e => {
            selectedBrochuresIndexList = e;
          }}
          size="large"
          optionLabelProp="label"
          defaultValue={[currentBrochureIndex]}
          menuItemSelectedIcon={
            <span className="fa-solid fa-square-check absolute top-[18px] left-[20px] text-accent_blue_2" />
          }
          showArrow
          getPopupContainer={() =>
            document.getElementById('download-multiple-brochures-modal')!
          }
        >
          {brochureList.map(({ name, description }, index) => {
            return (
              <Option
                key={name}
                value={index}
                label={name}
                className="select-option"
              >
                <span className="fa-light fa-square pt-[18px]" />
                <OptionLabel name={name} description={description} />
              </Option>
            );
          })}
        </Select>

        <HubspotForm
          formId="0861c41b-f266-4c36-8c27-1d0620ff4019"
          formTarget={`multiple-download-modal-${currentDate}`}
          onFormSubmitted={() => {
            setIsModalOpen(false);
            setIsSubmitted(true);
            if (selectedBrochuresIndexList.length > 1) {
              downloadMultipleFiles(brochureList, selectedBrochuresIndexList);
            }
            if (selectedBrochuresIndexList.length === 1) {
              const { name, downloadLink } =
                brochureList[selectedBrochuresIndexList[0]];
              downloadPdf(downloadLink, `${name} Brochure`);
            }
            selectedBrochuresIndexList.forEach(index => {
              if (typeof window !== 'undefined') {
                window.dataLayer.push({
                  event: brochureList[index].dataLayer,
                });
              }
            });
          }}
          onFormSubmit={form => {
            const nameElement = form.querySelector(
              'input[name="brochure_name"]',
            );
            const name = selectedBrochuresIndexList.reduce(
              (acc, currentValue) => {
                return acc + ` + ${brochureList[currentValue].name}`;
              },
              '',
            );
            nameElement.value = name;
            nameElement.dispatchEvent(new Event('input', { bubbles: true }));
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default BrochureModalContent;
