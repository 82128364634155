import LanguageProvider from 'LanguageProvider/index';
import React, { useEffect, useState } from 'react';
import translation from './translation';
import BrochuresHero from './BrochuresHero';
import BrochuresFilter from './BrochuresFilter';
import LayoutContainer from 'components/LayoutContainer';
import BrochuresList from './BrochuresList';
import { graphql, useStaticQuery } from 'gatsby';
import withConvertToFilterList from 'components/common/withConvertToFilterList';
import { BrochureCardType } from 'components/types';
import NoResultFound from 'components/noResultFound';

const BrochuresIndex = () => {
  const [currentList, setCurrentList] = useState<BrochureCardType[]>([]);
  const [sortSelected, setSortSelected] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');

  const data = useStaticQuery(graphql`
    query query {
      allStrapiBrochureType {
        nodes {
          name
          slug
        }
      }
      allStrapiBrochure(sort: { order: ASC, fields: strapi_id }) {
        nodes {
          brochureName
          brochure_type {
            name
            slug
          }
          dataLayer
          description
          downloadLink
          image {
            url
          }
        }
      }
    }
  `);
  const convertedBrochureList: BrochureCardType[] =
    data?.allStrapiBrochure?.nodes.map(
      ({
        brochureName: name,
        brochure_type: type,
        dataLayer,
        description,
        downloadLink,
        image: { url },
      }: Record<string, any>) => ({
        dataLayer,
        type,
        name,
        description,
        imgSrc: url,
        downloadLink,
      }),
    );
  useEffect(() => {
    setCurrentList(convertedBrochureList);
  }, [data]);

  const typeList = withConvertToFilterList(
    data?.allStrapiBrochureType?.nodes,
    'type',
    'Type',
  );
  typeList?.items?.length > 1 &&
    typeList.items.unshift({ value: 'all', label: 'All' });
  useEffect(() => {
    setSortSelected(typeList?.items?.[0].value);
  }, [typeList?.items?.length]);

  const handleSearch = (list: BrochureCardType[]) => {
    if (!searchKey) setCurrentList(list);
    else {
      const searchedList = list.filter(({ name }) =>
        name.toLowerCase().includes(searchKey.trim().toLowerCase()),
      );
      setCurrentList(searchedList);
    }
  };

  useEffect(() => {
    if (sortSelected === 'all') {
      handleSearch(convertedBrochureList);
    } else {
      const filteredList = convertedBrochureList.filter(
        ({ type: { slug } }) => slug === sortSelected,
      );
      handleSearch(filteredList);
    }
  }, [searchKey, sortSelected]);

  return (
    <LanguageProvider messages={translation}>
      <div className="lg:bg-accent_grey_1 lg:pt-[74px] pb-[60px]">
        <LayoutContainer>
          <BrochuresHero setSearchKey={setSearchKey} />
          <BrochuresFilter
            setSearchKey={setSearchKey}
            typeList={typeList}
            sortSelected={sortSelected}
            setSortSelected={setSortSelected}
          />
          {currentList.length === 0 ? (
            <NoResultFound />
          ) : (
            <BrochuresList
              currentList={currentList}
              brochureList={convertedBrochureList}
            />
          )}
        </LayoutContainer>
      </div>
    </LanguageProvider>
  );
};

export default BrochuresIndex;
