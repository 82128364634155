import { TagType } from 'components/types';
import React from 'react';
import tw from 'twin.macro';

const TAGS_STYLE = {
  'news-articles': {
    css: 'text-hubble_blue bg-[#E4EEFB] bg-opacity-50',
  },
  'customer-success': {
    css: 'text-[#C16A1B] bg-[#FDF5EE]',
  },
  'hubble-update': {
    css: 'text-[#2E992E] bg-[#EEF7EE]',
  },
  thoughtLeadership: {
    css: 'text-[#C16A1B] bg-[#FDF5EE]',
  },
  hubbleTeam: {
    css: 'text-hubble_blue bg-[#E4EEFB] bg-opacity-50',
  },
  webinars: {
    css: 'text-[#C16A1B] bg-[#FDF5EE]',
  },
  events: {
    css: 'text-hubble_blue bg-[#E4EEFB] bg-opacity-50',
  },
  product: {
    css: 'text-hubble_blue bg-[#E4EEFB] bg-opacity-50',
  },
  guide: {
    css: 'text-[#C16A1B] bg-[#FDF5EE]',
  },
};

interface ArticleTagsProps {
  name: string;
  textHexColorCode?: string;
  backgroundHexCodeColor?: string;
}

const TagWrapper = tw.span`
  text-[14px] font-[500] py-1 px-3 
`;

const ArticleTags = ({
  name,
  textHexColorCode = '#162685',
  backgroundHexCodeColor = '#E0EDFF',
}: ArticleTagsProps) => {
  const { css } = TAGS_STYLE['news-articles'];
  return (
    <TagWrapper
      style={{
        color: textHexColorCode,
        backgroundColor: backgroundHexCodeColor,
      }}
    >
      {name}
    </TagWrapper>
  );
};

export default ArticleTags;
